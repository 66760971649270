import React, { useEffect, useRef, useState } from "react";

type Props = {
  question: string;
  questionType: number;
  answer: string;
  showAnswer: boolean;
  sendCommand: (command: any) => any;
};

const Question = ({
  question,
  questionType,
  answer,
  showAnswer,
  sendCommand,
}: Props) => {
  useEffect(() => {}, []);

  return (
    <div className="flex flex-wrap">
      <div className="text-center flex w-12 justify-content-center pb-3">
        <span
          className="bg-red-500 p-5 border-round-md"
          onClick={() =>
            sendCommand({
              command: "buzz",
              playerID: localStorage.getItem("playerID"),
              playerName: localStorage.getItem("playerName"),
            })
          }
        >
          <i className="pi pi-bell text-5xl"></i>
        </span>
      </div>
      <div className="flex flex-wrap py3 w-12 border-bottom-2 border-top-2 ">
        {questionType === 1 ? (
          <div className={`flex w-12 p-2 text-xl`}>Erkenne das Theme</div>
        ) : questionType === 2 ? (
          <div className={`flex w-12 p-2 text-xl`}>Erkenne die Szene</div>
        ) : (
          <div
            className={`w-12 p-2 text-${
              question.length < 400 ? "4" : question.length < 800 ? "3" : "2"
            }xl`}
            dangerouslySetInnerHTML={{
              __html: question,
            }}
          ></div>
        )}
        {showAnswer && <div className={`w-12 pb-5 text-4xl`}>{answer}</div>}
      </div>
    </div>
  );
};

export default Question;
