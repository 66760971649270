import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo.png";
import { Image } from "primereact/image";

type Props = {
  width: string;
  className: string;
  click: (value: boolean) => void;
};

const Logo = ({ width, className, click }: Props) => {
  return (
    <Image
      src={logo}
      alt="Image"
      width={width}
      className={className}
      onDoubleClick={() => click(true)}
    />
  );
};

export default Logo;
