import { useState } from "react";
import Footer from "./Footer";
import Header from "./Header";

type Props = {
  children: React.ReactNode;
  breadcrump: { label: string; href: string }[];
  points: any;
  questionType: number;
  showAnswer: boolean;
  sendCommand: (commmand: any) => void;
  setShowAnswer: (value: boolean) => void;
};

const Layout = ({
  children,
  breadcrump,
  points,
  questionType,
  showAnswer,
  sendCommand,
  setShowAnswer,
}: Props) => {
  const [isAdmin, setIsAdmin] = useState(false);
  return (
    <div>
      <div className="min-h-screen flex flex-column surface-ground">
        <Header breadcrump={breadcrump} setIsAdmin={setIsAdmin} />
        <div className="p-5 flex flex-column flex-auto">
          <div className="border-round flex-auto">{children}</div>
        </div>
        <Footer
          isAdmin={isAdmin}
          points={points}
          questionType={questionType}
          showAnswer={showAnswer}
          sendCommand={sendCommand}
          setShowAnswer={setShowAnswer}
        />
      </div>
    </div>
  );
};

export default Layout;
