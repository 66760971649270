import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "primereact/resources/themes/lara-dark-indigo/theme.css";
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import "primeflex/primeflex.css";

import { BrowserRouter } from "react-router-dom";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

if (!window.electron) {
  window.electron = {
    store: {
      get(val: any) {
        let getItem: any = localStorage.getItem(val);
        return JSON.parse(getItem);
      },
      set(property: any, val: any) {
        localStorage.setItem(property, JSON.stringify(val));
      },
      // Other method you want to add like has(), reset(), etc.
    },
  };
}

declare global {
  interface Window {
    electron:
      | {
          store: {
            get: (key: string) => any;
            set: (key: string, val: any) => void;
            // any other methods you've defined...
          };
        }
      | any;
  }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
