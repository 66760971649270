import React, { useState } from "react";
import { Button } from "primereact/button";

type Props = {
  isAdmin: boolean;
  points: any;
  questionType: number;
  showAnswer: boolean;
  sendCommand: (command: any) => void;
  setShowAnswer: (value: boolean) => void;
};

const Footer = ({
  isAdmin,
  points,
  questionType,
  showAnswer,
  sendCommand,
  setShowAnswer,
}: Props) => {
  return (
    <>
      <div className="surface-section px-4 md:px-6 lg:px-8">
        <div className="surface-section py-6 flex flex-column sm:flex-row align-items-center justify-content-between">
          <div className="flex flex-wrap flex-nowap font-medium">
            {isAdmin && (
              <>
                <div className="flex w-6 lign-items-center text-left">
                  <i
                    className="pi pi-thumbs-down"
                    onClick={() => {
                      sendCommand({ command: "wrongAnswer" });
                    }}
                    style={{ fontSize: "2rem" }}
                  ></i>
                  <i
                    className="ml-2 pi pi-check-circle"
                    onClick={() => {
                      sendCommand({ command: "rightAnswer" });
                    }}
                    style={{ fontSize: "2rem" }}
                  ></i>
                  <i
                    className="ml-2 pi pi-step-backward-alt"
                    onClick={() => {
                      sendCommand({ command: "previousQuestion" });
                    }}
                    style={{ fontSize: "2rem" }}
                  ></i>
                  <i
                    className="ml-2 pi pi-step-forward-alt"
                    onClick={() => {
                      sendCommand({ command: "nextQuestion" });
                    }}
                    style={{ fontSize: "2rem" }}
                  ></i>
                  <i
                    className={`ml-2 pi pi-eye${showAnswer ? "-slash" : ""}`}
                    onClick={() => setShowAnswer(!showAnswer)}
                    style={{ fontSize: "2rem" }}
                  ></i>
                  <i
                    className="ml-3 pi pi-image"
                    onClick={() => {
                      sendCommand({ command: "showPopup" });
                    }}
                    style={{ fontSize: "2rem" }}
                  ></i>
                  {(questionType === 1 || questionType === 2) && (
                    <>
                      <i
                        className="ml-3 pi pi-volume-down"
                        onClick={() => {
                          sendCommand({ command: "playSound" });
                        }}
                        style={{ fontSize: "2rem" }}
                      ></i>
                      <i
                        className="ml-3 pi pi-video"
                        onClick={() => {
                          sendCommand({ command: "playVideo" });
                        }}
                        style={{ fontSize: "2rem" }}
                      ></i>
                    </>
                  )}
                </div>
                <div className="flex px-5 w-6 lign-items-center justify-content-center">
                  {Object.keys(points).map((key: any) => {
                    return (
                      <div className="mx-2 flex text-4xl">
                        <Button
                          icon="pi pi-minus"
                          aria-label="Filter"
                          className="p-button-outlined p-button-rounded mr-2"
                          onClick={() =>
                            sendCommand({
                              command: "decreasePoints",
                              playerID: key,
                            })
                          }
                        />
                        {points[key].playerName}
                        <Button
                          icon="pi pi-plus"
                          aria-label="Filter"
                          className="p-button-outlined p-button-rounded ml-2"
                          onClick={() =>
                            sendCommand({
                              command: "increasePoints",
                              playerID: key,
                            })
                          }
                        />
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
