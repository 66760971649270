import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  NavLink,
} from "react-router-dom";
import "./App.css";
import Layout from "./components/Layout/Layout";
import Question from "./components/questions/pages/Question";
import { useEffect, useRef, useState } from "react";
import { ws } from "./logik/websocket";

function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const [question, setQuestion] = useState("Es gibt aktuell keine Frage");
  const [answer, setAnswer] = useState("Es gibt aktuell keine Antwort");
  const [showAnswer, setShowAnswer] = useState(false);
  const [questionType, setQuestionType] = useState(0);
  const points = useRef<any>({});

  const navigate = useNavigate();
  useEffect(() => {
    ws.onmessage = (event: any) => {
      let message = JSON.parse(decodeURIComponent(event.data));
      console.log(event);

      if ("question" in message) {
        setQuestionType(message.type);
        setQuestion(message.question as string);
        setAnswer(message.answer as string);
        setShowAnswer(false);
      } else if ("command" in message && message.command === "buzz") {
        if (!(message.playerID in points.current)) {
          points.current[message.playerID] = {
            playerName: message.playerName,
            playerPoints: 0,
          };
        } else {
          points.current[message.playerID].playerName = message.playerName;
        }
      }
    };

    navigate("/question");
  }, []);

  const sendCommand = (command: any) => {
    ws.send(encodeURIComponent(JSON.stringify(command)));
  };

  const routes = [
    {
      path: "/question",
      name: "Question",
      component: (
        <Question
          question={question}
          questionType={questionType}
          answer={answer}
          showAnswer={showAnswer}
          sendCommand={sendCommand}
        />
      ),
      needsAuthentication: false,
      breadcrump: [{ label: "Frage", href: "/#" }],
    },
  ];

  return (
    <div className="App">
      <Routes>
        {routes.map((route) => {
          return (
            (!route.needsAuthentication || authenticated) && (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <>
                    <Layout
                      breadcrump={route.breadcrump}
                      points={points.current}
                      questionType={questionType}
                      showAnswer={showAnswer}
                      sendCommand={sendCommand}
                      setShowAnswer={setShowAnswer}
                    >
                      {route.component}
                    </Layout>
                  </>
                }
              ></Route>
            )
          );
        })}
        <Route path="/" element={<Navigate replace to="/question" />}></Route>
        <Route
          path="*"
          element={
            <div>
              404
              <br />
              <NavLink to="/">HIER ZUR START</NavLink>
            </div>
          }
        ></Route>
      </Routes>
    </div>
  );
}

export default App;
