import React, { useEffect, useRef, useState } from "react";
import { Ripple } from "primereact/ripple";
import { StyleClass } from "primereact/styleclass";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../general/Logo";
import { InputText } from "primereact/inputtext";

type Props = {
  breadcrump: { label: string; href: string }[];
  setIsAdmin: (value: boolean) => void;
};

const Header = ({ breadcrump, setIsAdmin }: Props) => {
  const menu = useRef(null);
  const [playerName, setPlayerName] = useState<any>(
    localStorage.getItem("playerName") ? localStorage.getItem("playerName") : ""
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("playerID")) {
      localStorage.setItem(
        "playerID",
        Math.random()
          .toString(36)
          .replace(/[^a-z]+/g, "")
          .substr(0, 32)
      );
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("playerName", playerName);
  }, [playerName]);

  return (
    <>
      <div
        className="surface-overlay px-6 shadow-2 flex justify-content-between relative lg:static"
        style={{ minHeight: "84px" }}
      >
        <div>
          <Logo
            width="75px"
            className="mr-0 lg:mr-6 align-self-center"
            click={setIsAdmin}
          />
        </div>
        <div>
          Dein Spielername:{" "}
          <InputText
            value={playerName}
            className="max-h-2rem mt-5"
            onChange={(e) => setPlayerName(e.target.value)}
          />
        </div>
        {/*         <>
          <StyleClass
            nodeRef={menu}
            selector="@next"
            enterClassName="hidden"
            leaveToClassName="hidden"
            hideOnOutsideClick
          >
            <div
              ref={menu}
              className="p-ripple cursor-pointer block lg:hidden align-self-center text-700"
            >
              <i className="pi pi-bars text-4xl"></i>
              <Ripple />
            </div>
          </StyleClass>
          <div className="flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full surface-overlay left-0 top-100 z-1 shadow-2 lg:shadow-none">
            <ul className="list-none p-0 m-0 flex select-none flex-column lg:flex-row">
              <li
                onClick={() => {
                  navigate("/question");
                }}
              >
                <div className="p-ripple flex px-6 p-3 h-full lg:px-3 lg:py-2 align-items-center text-600 border-left-2 lg:border-bottom-2 lg:border-left-none border-transparent hover:border-500 font-medium cursor-pointer transition-colors transition-duration-150 w-full">
                  <i className="pi pi-ticket mr-2"></i>
                  <span>Frage</span>
                  <Ripple />
                </div>
              </li>
            </ul>
          </div>
        </> */}
      </div>
    </>
  );
};

export default Header;
